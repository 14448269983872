import styled from 'styled-components';

export const ConnectWalletWidgetWrapper = styled.button`
    width: 100%;
    border: none;
    color: white;
    text-transform: uppercase;
    font-size: 40px;
    min-height: 300px;
    background-color: rgba(57, 62, 67, 0.4)
`;

