import styled from 'styled-components';

export const IconWrapper = styled.button`
    position: fixed;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-text-secondary);
    right: 15px;
    bottom: 15px;
    z-index: 990;
    background-color: var(--color-main);
    color: var(--color-text-secondary);
    width: 40px;
    height: 40px;
    border-radius: 10px;
    transition: all 0.4s;
    font-size: 28px;
    line-height: 0;
    &:focus {
        background-color: var(--color-main);
        color: var(--color-text);
    }
    &:hover {
        background-color: var(--color-secondary);
        color: var(--color-text);
    }
    @media (hover: none) {
      &:hover { 
        background: var(--color-main);
        color: var(--color-text);
      }
    }
`;