//import { NavLink } from "react-bootstrap";
import { ImageSource } from "types/contracts";
import { CardOuter, CardWrapper, Image, ImageWrapper } from "./style";

export default function ImageCard(props: ImageSource) {
  return (
    <CardOuter>
      <CardWrapper>
        {/*<NavLink onClick={(e) => {e.preventDefault();window.open(`https://knowhere.art/nft/terra19j7859076jveetfhdskc6hwjnr7cpt2t78llp8nz9qnaqtgvj8ns0qgp9j/${props.tokenId}`,"_blank")}} aria-label="project">*/}         
          {props.alt}
          <ImageWrapper>
            <Image src={props.src} alt={props.alt} />
          </ImageWrapper>
        {/*</NavLink>*/}
      </CardWrapper>
    </CardOuter>
  );
}