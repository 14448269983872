import styled from 'styled-components';

export const TabWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const MiddleSection = styled.div`
    margin: 20px 0px 5px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;