import React from "react";
import { Modal } from "react-bootstrap";

interface ModalData {
    title: string | JSX.Element;
    body: string | JSX.Element;
    footer?: string | JSX.Element;
    show: boolean;
    centered?: boolean;
    size: "sm" | "lg" | "xl";
    openModal: (title: string | JSX.Element, body: string | JSX.Element, footer?: string | JSX.Element) => void;
    closeModal: () => void;
}

const DefaultModal: ModalData = {
    title: "",
    body: "",
    footer: "",
    show: false,
    centered: true,
    size: "sm",
    openModal: () => null,
    closeModal: () => null
};
const ModalContext = React.createContext<ModalData>(DefaultModal);

const ModalPopup = () => {
    const { show, centered, title, body, footer, size, closeModal } = useModalContext();

    return (
        <Modal size={size} show={show} centered={centered} onHide={closeModal}>
            <Modal.Header className="ms-3 me-3" closeButton={true}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>{footer}</Modal.Footer>
        </Modal>
    );
};

export const useModalContext = () => {
    return React.useContext(ModalContext);
};

export const ModalProvider = (props: { children: any; }) => {
    const [data, setData] = React.useState<ModalData>(DefaultModal);
    return (
        <ModalContext.Provider value={{
            ...data,
            openModal: (title, body, footer) => {
                setData({ ...data, show: true, title: title, body: body, footer: footer });
            },
            closeModal: () => {
                setData({ ...data, show: false });
            }
        }}>
            <ModalPopup />
            {props.children}
        </ModalContext.Provider >
    );
};
