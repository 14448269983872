import { useLCDClient, useWallet, WalletStatus } from "@terra-money/wallet-provider";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Ids, ImageSource } from "types/contracts";
import ImageCard from "../ImageCard/ImageCard";
import { MiddleSection, TabWrapper } from "./style";


interface NftMetadata {
  token_uri: string,
  extension: any;
};

export default function MferTab() {
  const contractAddress: string = process.env.REACT_APP_MFER_CONTRACT_ADDRESS!;

  const { status, wallets } = useWallet();
  const lcd = useLCDClient();

  const [images, setImages] = useState<ImageSource[]>([]);

  useEffect(() => {
    if (status !== WalletStatus.WALLET_CONNECTED) {
      return;
    }
    getContractInfo();
  }, [status]);

  const getContractInfo = async () => {
    const tokenIds: string[] = [];
    let tokenList: Ids;
    let startAfter: any = null;
    do {
      tokenList = await lcd.wasm.contractQuery<Ids>(contractAddress,
        { tokens: { owner: wallets[0].terraAddress, start_after: startAfter } }
      );
      const tokens = tokenList.ids;
      if (tokens.length > 0) {
        tokenIds.push(...tokens);
        startAfter = tokens[tokens.length - 1];
      }
    } while (tokenList.ids.length > 0);

    const queries = tokenIds.map(id => {
      return lcd.wasm.contractQuery<NftMetadata>(contractAddress, { nft_info: { token_id: id } });
    });
    const nftMetadata: NftMetadata[] = await Promise.all(queries);
    const requests = nftMetadata.map(nft => fetch(nft.token_uri));

    const result = await Promise.all(requests)
      .then(res => {
        return Promise.all(res.map(r => r.json()));
      })
      .then(tokens => {
        return tokens.map((metadata, index) => {
          return (
            {
              tokenId: tokenIds[index],
              src: metadata.media,
              alt: metadata.title
            } as ImageSource
          );
        });
      });
    setImages(result);

    console.log(result);
  };

  const middleSection = () => {
    return (
      <Row>
        {
          images.map((image, index) => {
            return (
              <Col xs={12} lg={4} key={index}>
                <ImageCard {...image} />
              </Col>
            );
          })
        }
      </Row>
    );
  };

  return (
    <TabWrapper>
      <MiddleSection>
        {middleSection()}
      </MiddleSection>
    </TabWrapper>
  );
}