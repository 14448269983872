import styled from 'styled-components';

export const TabWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const MiddleSection = styled.div`
    margin: 20px 0px 5px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ModalTitle = styled.div`
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    margin-bottom: 10px;
`;

export const ModalBody = styled.div`
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    color: white;
`;

export const ModalFooter = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 20px;
`;

export const ModalButton = styled.button`
    font-size: 18px;
    text-transform: uppercase;
    color: white;
    background-color: grey;
    border-radius:8px;
    &:hover{
        color:red;
        background-color:white;
    };
`;