import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --mc1: #de3633;
    --mc2: #7000ff;
    --hc: #ddd;
    --bc: #9ba0b8;
    --hff: 'Bakbak One', sans-serif;
    --bff: 'Bakbak One', sans-serif;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: var(--bff);
    color: var(--hc);
    background-image: url("/background.png");
    background-color: #000;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  p {
    margin-bottom: 32px;
    line-height: 24px;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: var(--hff);
    font-weight: 800;
    color: var(--hc);
    text-transform: uppercase;
  }

  @keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;