import React from "react";
import { BsList, BsX } from "react-icons/bs";
import { MobileNavButtonWrapper } from "./style";

export default function MobileNavButton(props: { onClick: React.MouseEventHandler<HTMLDivElement>; isMobileToggled: boolean; }) {
  return (
    <MobileNavButtonWrapper onClick={props.onClick}>
      {props.isMobileToggled ? <BsX /> : <BsList />}
    </MobileNavButtonWrapper>
  );
}
