import styled from 'styled-components';

export const HeaderWrapper = styled.header<{ isMobileToggled: boolean; }>`
    padding: 50px;
    background: rgba(0, 0, 0, 1);
    margin-bottom: 20px;
    width: 100%;
    min-height: 100%;
    display: ${props => (props.isMobileToggled ? "block" : "none")};
    position: ${props => (props.isMobileToggled ? "fixed" : "static")};
    z-index: 998;
     @media (min-width: 1199px) {
        background: rgba(0, 0, 0, 0);
        display: flex;
        height: fit-content;
    }
`;