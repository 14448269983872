import { useWallet, WalletStatus } from "@terra-money/wallet-provider";
import ConnectWalletWidget from "components/ConnectWalletWidget/ConnectWalletWidget";
import { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import ChestTab from "../ChestTab/ChestTab";
import MferTab from "../MferTab/MferTab";
import WeaponsTab from "../WeaponsTab/WeaponsTab";

export default function TabMenu() {
  const [tabKey, setTabKey] = useState('chest');

  const { status } = useWallet();

  useEffect(() => {
    if (status !== WalletStatus.WALLET_CONNECTED)
      return;
    console.log("connected");
  }, [status]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} lg={10}>
          {
            status === WalletStatus.WALLET_CONNECTED ?
              <Tabs
                activeKey={tabKey}
                onSelect={(k) => setTabKey(k!)}
                className="mb-3 mt-5 justify-content-center"
              >
                <Tab eventKey="chest" title="CHESTs">
                  <ChestTab />
                </Tab>
                <Tab eventKey="mfer" title="MFERs">
                  <MferTab />
                </Tab>
                <Tab eventKey="weapon" title="WEAPONs">
                  <WeaponsTab />
                </Tab>
              </Tabs>
              : <ConnectWalletWidget />
          }
        </Col>
      </Row>
    </Container>
  );
}