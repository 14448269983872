import { useLCDClient, useWallet, WalletStatus } from "@terra-money/wallet-provider";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CW721MetadataOnChain, ImageSource, Tokens } from "types/contracts";
import ImageCard from "../ImageCard/ImageCard";
import { MiddleSection, TabWrapper } from "./style";

export default function WeaponsTab() {
  const contractAddress: string = process.env.REACT_APP_CHEST_CONTRACT_ADDRESS!;

  const { status, wallets } = useWallet();
  const lcd = useLCDClient();

  const [images, setImages] = useState<ImageSource[]>([]);

  useEffect(() => {
    if (status !== WalletStatus.WALLET_CONNECTED) {
      return;
    }
    getContractInfo();
  }, [status]);

  const getContractInfo = async () => {
    const tokenIds: string[] = [];
    let tokenList: Tokens;
    let startAfter = "";
    do {
      tokenList = await lcd.wasm.contractQuery<Tokens>(contractAddress,
        { tokens: { owner: wallets[0].terraAddress, start_after: startAfter } }
      );
      const tokens = tokenList.tokens;
      if (tokens.length > 0) {
        tokenIds.push(...tokens);
        startAfter = tokens[tokens.length - 1];
      }
    } while (tokenList.tokens.length > 0);

    const queries = tokenIds.map(id => {
      return lcd.wasm.contractQuery<CW721MetadataOnChain>(contractAddress, { nft_info: { token_id: id } });
    });
    const nftMetadata: CW721MetadataOnChain[] = await Promise.all(queries);
    const result = nftMetadata.flatMap((metadata, index) => {
      const isWeapon = metadata.extension?.attributes.some(
        a => a.trait_type === "category" && a.value === "weapon"
      );
      if (!isWeapon)
        return [];
      return (
        {
          tokenId: tokenIds[index],
          src: metadata.extension?.image?.replace("ipfs://", "https://ipfs.io/ipfs/"),
          alt: metadata.extension?.name
        } as ImageSource
      );
    });
    setImages(result);
    console.log(result);
  };

  const middleSection = () => {
    return (
      <Row>
        {
          images.map((image, index) => {
            return (
              <Col xs={12} lg={4} key={index}>
                <ImageCard {...image} />
              </Col>
            );
          })
        }
      </Row>
    );
  };

  return (
    <TabWrapper>
      <MiddleSection>
        {middleSection()}
      </MiddleSection>
    </TabWrapper>
  );
}