import styled from 'styled-components';

export const MobileNavButtonWrapper = styled.div`
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 998;
    border: 1px solid var(--color-text-secondary);
    color: var(--color-text-secondary);
    background-color: var(--color-main);
    transition: all 0.4s;
    width: 39px;
    height: 41px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    font-size: 26px;
    border-radius: 15px;

    @media (min-width: 1199px) {
        display: none;
    }
`;