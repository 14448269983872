import styled from 'styled-components';

export const ConnectWalletExternalWrapper = styled.div`
    position: relative;
`;

export const ConnectWalletButtonWrapper = styled.button`
    border: none;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    padding: 14px 8px;
    background-color: rgba(57, 62, 67, 0.4);
`;

export const ConnectWalletInfo = styled.div<{ isOpen: boolean; }>`
    display: ${props => props.isOpen ? "block" : "none"};
    background-color: rgba(57, 62, 67, 1);
    text-align: center;
    position: absolute;
    padding: 10px 20px;
    bottom: 110%;
    left: 50%;
    transform: translate(-50%, 0);

    @media (min-width: 1199px) {
        top: 110%;
        bottom: auto;
    }
`;

export const DisconnectButton = styled.button`
    border: none;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    padding: 14px 8px;
    margin-top: 10px;
    background-color: grey;
`;

export const WalletTitle = styled.div`
    text-transform: uppercase;
    font-size: 26px;
`;