import ConnectWalletButton from "components/ConnectWalletButton/ConnectWalletButton";
import { Image, ImageWrapper, LinkWrapper, NavbarWrapper, NavLink, NavText, UnorderedList } from "./style";

export default function Navbar(props: { navCallback: () => void; isMobileToggled: boolean; }) {
  return (
    <NavbarWrapper>
      <a href="/" onClick={props.navCallback} title="home" aria-label="home" className="d-flex justify-content-center">
        <ImageWrapper>
          <Image src="/depeg_logo.png" alt="logo" />
        </ImageWrapper>
      </a>
      <UnorderedList isMobileToggled={props.isMobileToggled}>
        <LinkWrapper>
          <NavLink href="https://depeg.io" onClick={props.navCallback} title="project" aria-label="project">
            <NavText>Home</NavText>
          </NavLink>
        </LinkWrapper>
        <LinkWrapper>
          <NavLink href="https://www.depeg.io/#about" onClick={props.navCallback} title="features" aria-label="features">
            <NavText>About</NavText>
          </NavLink>
        </LinkWrapper>
        <LinkWrapper>
          <NavLink href="https://www.depeg.io/#roadmap" onClick={props.navCallback} title="contact" aria-label="contact">
            <NavText>Roadmap</NavText>
          </NavLink>
        </LinkWrapper>
        <LinkWrapper>
          <NavLink href="https://www.depeg.io/#team" onClick={props.navCallback} title="contact" aria-label="contact">
            <NavText>Team</NavText>
          </NavLink>
        </LinkWrapper>
        <LinkWrapper>
          <NavLink href="https://www.depeg.io/#faq" onClick={props.navCallback} title="contact" aria-label="contact">
            <NavText>FAQ</NavText>
          </NavLink>
        </LinkWrapper>
        <LinkWrapper>
          <NavLink href="https://whitepaper.depeg.io/" target="_blank" onClick={props.navCallback} title="contact" aria-label="contact">
            <NavText>Whitepaper</NavText>
          </NavLink>
        </LinkWrapper>
        <ConnectWalletButton />
      </UnorderedList>
    </NavbarWrapper>
  );
}
