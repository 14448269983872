import { Dec } from "@terra-money/terra.js";
import { ConnectType, useLCDClient, useWallet, WalletStatus } from "@terra-money/wallet-provider";
import { useEffect, useState } from "react";
import { BiWallet } from "react-icons/bi";
import { ConnectWalletButtonWrapper, ConnectWalletExternalWrapper, ConnectWalletInfo, DisconnectButton, WalletTitle } from "./style";

export default function ConnectWalletWidget() {
  const dustAddress: string = process.env.REACT_APP_DUST_CONTRACT_ADDRESS!;

  const { connect, disconnect, availableConnectTypes, status, wallets } = useWallet();
  const lcd = useLCDClient();
  const [widgetOpen, setWidgetOpen] = useState(false);
  const [lunaBalance, setLunaBalance] = useState("0");
  const [dustBalance, setDustBalance] = useState("0");

  useEffect(() => {
    if (status !== WalletStatus.WALLET_CONNECTED)
      return;
    updateBalance();
  }, [status]);

  const handleClick = () => {
    if (status !== WalletStatus.WALLET_CONNECTED) {
      const connectionType = availableConnectTypes.includes(ConnectType.EXTENSION)
        ? ConnectType.EXTENSION : ConnectType.WALLETCONNECT;
      connect(connectionType);
    }
    else {
      setWidgetOpen(o => !o);
    }
  };

  const onDisconnect = () => {
    disconnect();
    setWidgetOpen(false);
  };

  const updateBalance = () => {
    lcd.bank.balance(wallets[0].terraAddress).then(([coins]) => {
      const balance = coins.get("uluna")?.amount;
      if (balance === undefined)
        return;
      setLunaBalance(balance.dividedBy(10.0 ** 6).toString());
    });
    lcd.wasm.contractQuery<{ balance: string; }>(dustAddress,
      { balance: { address: wallets[0].terraAddress } }
    ).then(coins => {
      const balance = new Dec(coins.balance);
      setDustBalance(balance.dividedBy(10.0 ** 6).toString());
    });;
  };

  const finderUrl = () => "https://finder.terra.money/mainnet/address/" + wallets[0].terraAddress;

  return (
    <ConnectWalletExternalWrapper>
      <ConnectWalletButtonWrapper onClick={handleClick}>
        <BiWallet className="me-2" />
        {status !== WalletStatus.WALLET_CONNECTED ?
          <>Connect</> : <>{wallets[0].terraAddress.substring(0, 14)}...</>
        }
      </ConnectWalletButtonWrapper>
      {status === WalletStatus.WALLET_CONNECTED ?
        <ConnectWalletInfo isOpen={widgetOpen}>
          <WalletTitle>My wallet</WalletTitle>
          <div className="mt-2">MY ADDRESS</div>
          <div>{wallets[0].terraAddress}</div>
          <div className="mt-2">{lunaBalance} LUNA / {dustBalance} DUST</div>
          <div className="mt-4 mb-1">
            <a href={finderUrl()} target="_blank">VIEW ON FINDER</a>
          </div>
          <DisconnectButton onClick={onDisconnect}>Disconnect</DisconnectButton>
        </ConnectWalletInfo> : <></>
      }
    </ConnectWalletExternalWrapper>
  );
}