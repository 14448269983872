import styled from 'styled-components';

export const CardOuter = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const CardWrapper = styled.div`  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, .3);
    border: 2px solid rgba(255, 255, 255, .5);
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 20px 40px 40px;
    @media (min-width: 768px) {
        padding: 20px 68px 40px;
    }
    @media (min-width: 1199px) {
        padding: 20px 20px;
    }
`;
export const ImageWrapper = styled.div`
    margin: 20px 0px 10px;
    background: rgba(255, 255, 255, .05);
    border: 1px solid rgba(255, 255, 255, .5);
    transition: 0.3s;
    width: 100%;
    @media (min-width: 768px) {
        //max-width: 300px;
    }

    @media (min-width: 1199px) {
    }
`;

export const Image = styled.img`
    max-width: 100%;
    height: auto;
    user-drag: none; 
    user-select: none;
`;

export const Footer = styled.div`
    text-align: center;
`;

export const TimeLeft = styled.div<{ isStaked: boolean; }>`
    visibility: ${props => props.isStaked ? "visible" : "hidden"};
`;

export const BaseButton = styled.button`
    color: white;
    text-transform: uppercase;
    margin-top: 10px;
    padding: 2px;
    min-width: 80px;
    border-radius: 8px;
    border: 1px solid;
    border-color:white;
`;

export const StakeButton = styled(BaseButton)`
    background-color: green;
    &:hover{
        color:green;
        background-color:white;
    };
`;

export const OpenButton = styled(BaseButton)`
    background-color: brown;    
    &:hover{
        color:red;
        background-color:white;
    };
`;