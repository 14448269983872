import styled from 'styled-components';

export const NavbarWrapper = styled.nav`
    display: block;
    text-align: start;

    @media (min-width: 768px) {
        
    }

    @media (min-width: 1199px) {
        padding: 0;
        padding-top: 15px;  
        display: flex;
        justify-content: space-between;
        text-align: start;
    }
`;

export const ImageWrapper = styled.div`
    padding: 0px 40px;
    margin: auto;
    position: relative;
    display: flex;
    max-width: 300px;
`;

export const Image = styled.img`
    height: auto;
    max-width: 100%;
`;

export const Title = styled.h2`
    font-size: 22px;
    margin: 0 !important;
    padding: 0;
    font-weight: 600;
    font-family: var(--font-header);
`;

export const UnorderedList = styled.div<{ isMobileToggled: boolean; }>`
    display: block;
    margin-top: 50px;
    width: 100%;
    @media (min-width: 1199px) {
        margin-top: 0px;
        display: flex;
        align-items: center;
        gap: 15px;
    }
`;

export const LinkWrapper = styled.div`
    display: block;
    padding: 10px 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    @media (min-width: 1199px) {
        padding: 0;
    }
`;

export const NavLink = styled.a`
    text-align: start;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    padding: 0; 
    color: var(--bs-link-color);
    &:focus {
        color: var(--bs-link-color);
    }
    &:hover {
        color: var(--bs-link-hover-color);
    }

`;

export const NavText = styled.span`
    white-space: nowrap;
    width: 100%;
`;