import { Dec, MsgExecuteContract } from "@terra-money/terra.js";
import { useLCDClient, useWallet, WalletStatus } from "@terra-money/wallet-provider";
import ChestCard from "components/tabs/ChestCard/ChestCard";
import { useModalContext } from "modal/ModalContext";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { ChestStats, CW721MetadataOnChain, ImageSource, StakeData, Tokens, UnstakePreview } from "types/contracts";
import { MiddleSection, ModalBody, ModalButton, ModalFooter, ModalTitle, TabWrapper } from "./style";

export interface ChestData extends ImageSource {
  isStaked: boolean;
  timeLeftSeconds: number;
  stakeCallback: (id: string) => void;
  openCallback: (id: string) => void;
};

export default function ChestTab() {
  const [openedChests, setOpenedChests] = useState(0);
  const [stakedChests, setStakedChests] = useState(0);
  const [dustClaimed, setDustClaimed] = useState("0");
  const [dustBurned, setDustBurned] = useState("0");
  const decimals = 10 ** 6;
  const refreshDelay = 4000;

  const contractAddress: string = process.env.REACT_APP_CHEST_CONTRACT_ADDRESS!;
  const openerAddress: string = process.env.REACT_APP_OPENER_CONTRACT_ADDRESS!;
  const totalChests = 1250;

  const { openModal, closeModal } = useModalContext();

  const { status, wallets, post } = useWallet();
  const lcd = useLCDClient();

  const [chests, setChests] = useState<ChestData[]>([]);

  useEffect(() => {
    if (status !== WalletStatus.WALLET_CONNECTED) {
      return;
    }
    getContractInfo();
  }, [status]);

  const askStake = (tokenId: string) => {
    const body = <ModalTitle>Are you sure you want to stake this chest?</ModalTitle>;
    const footer = (
      <ModalFooter>
        <ModalButton onClick={() => stakeChest(tokenId)}>Confirm</ModalButton>
        <ModalButton onClick={closeModal}>Cancel</ModalButton>
      </ModalFooter>
    );
    openModal("", body, footer);
  };

  const askOpen = async (tokenId: string) => {
    const unstakePreview = await lcd.wasm.contractQuery<UnstakePreview>(openerAddress,
      { get_unstake_preview: { address: wallets[0].terraAddress, token_id: tokenId } }
    );
    const minQuantity = new Dec(unstakePreview.total_dust);
    const maxQuantity = new Dec(unstakePreview.max_dust);
    const burnedQuantity = maxQuantity.minus(minQuantity);
    const modalBody = minQuantity === maxQuantity
      ? `You will receive ${minQuantity.dividedBy(decimals)} dUST`
      : `You will receive ${minQuantity.dividedBy(decimals)} dUST, but if you wait until the end you will receive ${maxQuantity.dividedBy(decimals)} dust.\n
      If you open it now, ${burnedQuantity.dividedBy(decimals)} dUST will be burned.`;
    const body = <>
      <ModalTitle>Are you sure you want to open this chest?</ModalTitle>
      <ModalBody>{modalBody}</ModalBody>
    </>;
    const footer = (
      <ModalFooter>
        <ModalButton onClick={() => openChest(tokenId)}>Confirm</ModalButton>
        <ModalButton onClick={closeModal}>Cancel</ModalButton>
      </ModalFooter>
    );
    openModal("", body, footer);
  };

  const getContractInfo = async () => {
    const tokenIds: string[] = [];
    let tokenList: Tokens;
    let startAfter = "";
    do {
      tokenList = await lcd.wasm.contractQuery<Tokens>(contractAddress,
        { tokens: { owner: wallets[0].terraAddress, start_after: startAfter } }
      );
      const tokens = tokenList.tokens;
      if (tokens.length > 0) {
        tokenIds.push(...tokens);
        startAfter = tokens[tokens.length - 1];
      }
    } while (tokenList.tokens.length > 0);

    const allStakeData = await lcd.wasm.contractQuery<StakeData[]>(openerAddress, {
      get_all_stake_data: { address: wallets[0].terraAddress }
    });

    allStakeData.forEach(stake => {
      if (stake.token_id in tokenIds)
        return;
      tokenIds.push(stake.token_id);
    });

    console.log(tokenIds);

    const chestStats = await lcd.wasm.contractQuery<ChestStats>(openerAddress,
      { get_chest_stats: {} }
    );

    setDustBurned(() => new Dec(chestStats.burned_dust).divToInt(decimals).toString());
    setDustClaimed(() => new Dec(chestStats.claimed_dust).divToInt(decimals).toString());
    setOpenedChests(() => chestStats.opened_chests);
    setStakedChests(() => chestStats.staked_chests);

    const queries = tokenIds.map(id => {
      return lcd.wasm.contractQuery<CW721MetadataOnChain>(contractAddress, { nft_info: { token_id: id } });
    });
    const nftMetadata: CW721MetadataOnChain[] = await Promise.all(queries);
    const chestData = nftMetadata.flatMap((metadata, index) => {
      if (!metadata || !metadata.extension)
        return [];
      const isChest = metadata.extension?.attributes.some(
        a => a.trait_type === "category" && a.value === "chest"
      );
      if (!isChest)
        return [];
      return (
        {
          tokenId: tokenIds[index],
          src: metadata.extension?.image?.replace("ipfs://", "https://ipfs.io/ipfs/"),
          alt: metadata.extension?.name
        } as ImageSource
      );
    });

    const result = chestData.flatMap(data => {
      const stakeData = allStakeData.find(s => s.token_id === data.tokenId);
      const timeLeftSeconds = stakeData === undefined ? 0 :
        stakeData.final_stake_timestamp - stakeData.current_stake_timestamp;
      const isStaked = stakeData === undefined ? false : stakeData.staked;
      if (stakeData?.claimed)
        return [];
      return {
        tokenId: data.tokenId,
        src: data.src,
        alt: data.alt,
        timeLeftSeconds: timeLeftSeconds,
        isStaked: isStaked,
        stakeCallback: askStake,
        openCallback: askOpen
      } as ChestData;
    });
    setChests(result);
  };

  const openChest = (tokenId: string) => {
    closeModal();

    const tx = post({
      msgs: [
        new MsgExecuteContract(wallets[0].terraAddress, openerAddress,
          { unstake: { token_id: tokenId } })
      ],
    }, contractAddress).finally(() => {
      setTimeout(async () => {
        await getContractInfo();
      }, refreshDelay);
    });

    toast.promise(tx,
      {
        pending: 'Transaction is pending.',
        success: {
          render({ data }) {
            const msg = data!.success
              ? `Successfully opened chest Id ${tokenId}`
              : `Failed to open. Please try again.`;
            return msg;
          }
        },
        error: {
          render({ data }) {
            const msg = "Transaction has failed. Please try again.";
            console.error(data);
            return msg;
          }
        },
      }
    );
  };

  const stakeChest = async (tokenId: string) => {
    closeModal();

    const tx = post({
      msgs: [
        new MsgExecuteContract(wallets[0].terraAddress, contractAddress,
          { send_nft: { contract: openerAddress, token_id: tokenId, msg: "" } })
      ],
    }, contractAddress).finally(() => {
      setTimeout(async () => {
        await getContractInfo();
      }, refreshDelay);
    });

    toast.promise(tx,
      {
        pending: 'Transaction is pending.',
        success: {
          render({ data }) {
            const msg = data!.success
              ? `Successfully staked chest Id ${tokenId}`
              : `Failed to stake. Please try again.`;
            return msg;
          }
        },
        error: {
          render({ data }) {
            const msg = "Transaction has failed. Please try again.";
            console.error(data);
            return msg;
          }
        },
      }
    );
  };

  const middleSection = () => {
    return (
      <Row>
        {
          chests.map((chest, index) => {
            return (
              <Col xs={12} lg={4} key={index}>
                <ChestCard {...chest} />
              </Col>
            );
          })
        }
      </Row>
    );
  };

  return (
    <TabWrapper>
      <Row>
        <Col xs={12}>
          <div className="text-center">
            {openedChests}/{totalChests} OPENED - {stakedChests} STAKED - {dustClaimed} DUST CLAIMED - {dustBurned} DUST BURNED
          </div>
        </Col>
      </Row>
      <MiddleSection>
        {middleSection()}
      </MiddleSection>
    </TabWrapper>
  );
}