import Navbar from "../Navbar/Navbar";

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { HeaderWrapper } from "./style";

export default function Header(props: { isMobileToggled: boolean; navCallback: () => void; }) {

  return (
    <HeaderWrapper isMobileToggled={props.isMobileToggled}>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} lg={10}>
            <Navbar navCallback={props.navCallback} isMobileToggled={props.isMobileToggled} />
          </Col>
        </Row>
      </Container>
    </HeaderWrapper>
  );
}
