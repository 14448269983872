import { ChestData } from "../ChestTab/ChestTab";
import { CardOuter, CardWrapper, Footer, Image, ImageWrapper, OpenButton, StakeButton, TimeLeft } from "./style";

export default function ChestCard(props: ChestData) {

  const getTimeLeft = () => {
    const seconds = props.timeLeftSeconds;
    if (seconds === 0) {
      return "Ready";
    }
    const daysLeft = Math.floor(seconds / (3600 * 24));
    if (daysLeft > 0)
      return `${daysLeft} days`;
    const hoursLeft = Math.floor(seconds % (3600 * 24) / 3600);
    if (hoursLeft > 0)
      return `${hoursLeft} hours`;
    const minsLeft = Math.floor(seconds % 3600 / 60);
    return `${minsLeft} mins`;
  };

  return (
    <CardOuter>
      <CardWrapper>
      {props.alt}
        <ImageWrapper>
          <Image src={props.src} alt={props.alt} />
        </ImageWrapper>
        <Footer>
          <TimeLeft isStaked={props.isStaked}>
            Time left: {getTimeLeft()}
          </TimeLeft>
          {props.isStaked ?
            <OpenButton onClick={() => props.openCallback(props.tokenId)}>Open</OpenButton> :
            <StakeButton onClick={() => props.stakeCallback(props.tokenId)}>Stake</StakeButton>}
        </Footer>
      </CardWrapper>
    </CardOuter>
  );
}