import { getChainOptions, WalletProvider } from '@terra-money/wallet-provider';
import TabMenu from 'components/tabs/TabMenu/TabMenu';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

import BackToTopButton from './components/BackToTopButton/BackToTopButton';
import Header from './components/Header/Header';
import MobileNavButton from './components/MobileNavButton/MobileNavButton';
import { GlobalStyle } from './styles/style';

import { ModalProvider } from 'modal/ModalContext';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/_theme.scss';

function App() {
  const [isMobileToggled, setIsMobileToggled] = useState(false);
  const toggleMobile = () => {
    setIsMobileToggled(!isMobileToggled);
    document.body.classList.toggle('no-scroll');
  };
  const navCallback = () => {
    setIsMobileToggled(false);
    document.body.classList.remove('no-scroll');
  };

  return (
    <main>
      <GlobalStyle />
      <Header isMobileToggled={isMobileToggled} navCallback={navCallback} />
      <TabMenu />
      <ToastContainer />
      <BackToTopButton />
      <MobileNavButton isMobileToggled={isMobileToggled} onClick={toggleMobile} />
    </main>
  );
}

getChainOptions().then((chainOptions) => {
  ReactDOM.render(
    <WalletProvider {...chainOptions}>
      <ModalProvider>
        <App />
      </ModalProvider>
    </WalletProvider>,
    document.getElementById('root'),
  );
});
