import { ConnectType, useWallet } from "@terra-money/wallet-provider";
import { ConnectWalletWidgetWrapper } from "./style";

export default function ConnectWalletWidget() {
  const { connect, availableConnectTypes } = useWallet();

  const handleClick = () => {
    const connectionType = availableConnectTypes.includes(ConnectType.EXTENSION)
      ? ConnectType.EXTENSION : ConnectType.WALLETCONNECT;
    connect(connectionType);
  };
  return (
    <ConnectWalletWidgetWrapper onClick={handleClick}>
      Connect your Wallet
    </ConnectWalletWidgetWrapper>
  );
}