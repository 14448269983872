import { useEffect, useState } from "react";
import { BsArrowUpShort } from "react-icons/bs";
import { IconWrapper } from "./style";

export default function BackToTopButton() {
  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);

  useEffect(() => {
    const headerScrolled = () => {
      setIsHeaderScrolled(window.scrollY > 100);
    };
    window.addEventListener("scroll", headerScrolled);
  }, [setIsHeaderScrolled]);

  const backToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <IconWrapper onClick={backToTop}
      className={isHeaderScrolled ? "d-flex" : "d-none"}>
      <BsArrowUpShort />
    </IconWrapper>
  );
}
