import styled from 'styled-components';

export const CardOuter = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const CardWrapper = styled.div`  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, .3);
    border: 2px solid rgba(255, 255, 255, .5);
    margin-bottom: 20px;
    padding: 20px 40px 40px;

    border-radius: 10px;
    @media (min-width: 768px) {
        padding: 20px 68px 40px;
    }
    @media (min-width: 1199px) {
        padding: 20px 20px;
    }
`;
export const ImageWrapper = styled.div`
    background: rgba(255, 255, 255, .05);
    border: 1px solid rgba(255, 255, 255, .5);
    transition: 0.3s;
    min-width: 100%;

    @media (min-width: 768px) {
        //max-width: 300px;
    }

    @media (min-width: 1199px) {
    }
`;

export const Image = styled.img`
    max-width: 100%;
    height: auto;
    user-drag: none; 
    user-select: none;
`;